import React, { createContext, useEffect, useState, useReducer, useRef } from 'react';
import Home from './components/Home';
import Products from './components/Products';
import ShoppingCart from './components/ShoppingCart';
import Impressum from './components/Impressum';
import Tab from '@material-ui/core/Tab';
import './App.css';
import {useLocalStorage} from './useLocalStorage';
import reducer from './reducer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import Toolbar  from '@material-ui/core/Toolbar';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {useStyles} from './globalStyles';
// import {HomeIcon, ShoppingCartIcon, AddIcon} from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AddIcon from '@material-ui/icons/Add';
import {
  isMobile
} from "react-device-detect";

const routingItems = [
  { id: "home", icon: <HomeIcon/>, label: "Start", path: "/" },
  { id: "products", icon: <AddIcon/>, label: "Produkte", path: "/products" },
  { id: "shoppingcart", icon: <ShoppingCartIcon/>, label: "Warenkorb", path: "/shoppingcart"},
  // { id: "impressum", /*icon: cart,*/ label: "Impressum", path: "/impressum"},
];

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2E8099'
    }, 
    secondary:{
      main:'#ffffff'
    }
  }
});

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const localStorageVersionNumber = "1.0.0";
  const [products, setProducts] = useLocalStorage('products'.concat(localStorageVersionNumber), []);
  const [shoppingCartProducts, setShoppingCartProducts] = useLocalStorage('shoppingCartProducts'.concat(localStorageVersionNumber), []);
  const [favorites, setFavorites] = useLocalStorage('favorites'.concat(localStorageVersionNumber), []);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedNews, setSelectedNews] = useState();
  const [showNewsModal, setShowNewsModal] = useState(false);
  const [selectedFetchtime, setSelectedFetchtime] = useLocalStorage('selectedFetchtime'.concat(localStorageVersionNumber), );
  const [state, dispatch] = useReducer(reducer, {
    products: products,
    shoppingCartProducts: shoppingCartProducts,
    favorites: favorites,
    selectedProduct: selectedProduct,
    selectedNews: selectedNews,
    showNewsModal: showNewsModal,
    selectedFetchtime: selectedFetchtime,
  });

  const value = {state, dispatch};

  useEffect(() => {
    setProducts(state.products)
    setShoppingCartProducts(state.shoppingCartProducts)
    setFavorites(state.favorites)
    setSelectedProduct(state.selectedProduct)
    setSelectedNews(state.selectedNews)
    setShowNewsModal(state.showNewsModal)
    setSelectedFetchtime(state.selectedFetchtime)
  }, [
    state,
    setProducts,
    setShoppingCartProducts,
    setFavorites,
    setSelectedProduct,
    setSelectedNews,
    setShowNewsModal,
    setSelectedFetchtime
  ]);

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

function App() {
  const classes = useStyles();
  const [, forceUpdate] = useReducer(x => x + 1,0);
  const navigationTopBar = useRef(null);
  const [navigationHeight, setNavigationHeight] = useState(1000);

  useEffect(() => {
    setNavigationHeight(navigationTopBar.current.offsetHeight);
  
    function updateSize() {
      forceUpdate();
      console.log("resize. innerWidth: " + window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <AppContextProvider>
      <MuiThemeProvider theme={theme}>
        <Router>
          {/* <TopNavigationBar ref={navigationTopBar}/> */}
          <Toolbar ref={navigationTopBar} disableGutters={false} variant="dense" className={classes.navbarSticky}>
            {routingItems.map((routingItem) => (
              <Button key={routingItem.id}>
                <Link style={{ textDecoration: 'none', textTransform: 'none' }} className={classes.navbarText} to={routingItem.path}>
                  {isMobile ? <Tab label={routingItem.label} icon={routingItem.icon} {...routingItem.label} />
                  :
                  routingItem.label
                  }
                </Link>
              </Button >
            ))}
          </Toolbar>
          
          <Switch >
            <Route key="impressum" path="/impressum">
              <Impressum />
            </Route>
            <Route key="products" path="/products">
              <Products heightNavigationBar={navigationHeight}/>
            </Route>
            <Route key="shoppingcart" path="/shoppingcart">
              <ShoppingCart heightNavigationBar={navigationHeight}/>
            </Route>
            <Route key="home" path="/">
              <Home heightNavigationBar={navigationHeight}/>
            </Route>
          </Switch>
        </Router>
      </MuiThemeProvider>
    </AppContextProvider>
  );
}

export default App;
