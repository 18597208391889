import React, { useRef, useState, useContext} from 'react';
import { AppContext } from '../App';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import RsImg from '../utils/RsImg';
import ProductLabel from './ProductLabel';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ButtonGroup from '@material-ui/core/ButtonGroup';

/*
Anforderungen: 

*/

function ProductCard({product}){
    const { state, dispatch } = useContext(AppContext);
    const [productPreviewLoaded, setProductPreviewLoaded] = useState(false);
    const rootDiv = useRef(null);
    const classes = useStyles();

    return (
        <div width={"100%"} ref={rootDiv}>
            <Card className={classes.homeProductPreviewCard}>
                <Grid container spacing={1} style={{position:"relative"}}>
                    <Grid item xs={5}>
                        <RsImg 
                            onRsLoad={() => setProductPreviewLoaded(true)} 
                            ratio={1}
                            // imgHeight={gridHomeImg ? (gridHomeImg.current.offsetWidth/10)*4 : 10} 
                            imgSrc={"https://wms.regioshopper.com/images/product_" + product.id + ".png"}
                            originHeight={140}
                            >
                        </RsImg>
                        <ProductLabel product={product} size="small"/>
                        {/* <img ratio={1} src={"https://wms.regioshopper.com/images/bioland.png"}/> */}
                    </Grid>
                    <Grid item xs={6}>
                        {productPreviewLoaded ?
                        <CardContent>
                            <Typography gutterBottom variant="subtitle1" component="h6">
                                {product.title}
                            </Typography>
                            <Typography variant="body1" color="textSecondary" component="p">
                                {product.price.toFixed(2)}{" €"}{product.metric ? " / " : ""} {product.metric}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {product.hint}
                            </Typography>
                        </CardContent>
                        :
                        <CardContent>
                        <Skeleton variant="text"/>
                        <Skeleton variant="text"/>
                        <Skeleton variant="text"/>
                        <Skeleton variant="text"/>
                        </CardContent>
                        }
                    </Grid>
                    <Grid item xs={1}>
                        {productPreviewLoaded &&
                        <div >
                            <Button style={{position: "absolute", top:10, right: 10}}>
                                {/* <StarIcon color={"primary"}/> */}
                                {/* <StarBorderIcon color={"primary"}/> */}
                            </Button>           
                            {addShoppingCartIcon(product)}
                        </div>
                        }
                    </Grid>
                </Grid>
            </Card>
        </div>
    )

    function addShoppingCartIcon(product){
        if(isAvailable(product)){
            if(existsInShoppingCart(product)){
                return(
                    <div style={{position: "absolute", bottom:10, right: 10}} color="white">
                        <Typography color="textSecondary" variant="body2" align="right">= {(product.price * getShoppingCartItem(product).quantity).toFixed(2)} €</Typography>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button className="removeFromShoppingCartButton" color="primary" size="small" fill="clear" onClick={() => addToShoppingCart(product, -1)}>
                                <RemoveIcon slot="icon-only"/>
                            </Button>
                            <Button size="small" fill="clear">
                                {getShoppingCartItem(product).quantity}
                            </Button>
                            <Button className="addToShoppingCartButton" size="small" color="primary" fill="solid" onClick={() => addToShoppingCart(product, 1)}>
                                <AddIcon slot="icon-only"/>
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            }else{
                return(
                    <Button style={{position: "absolute", bottom:10, right: 10}} color={"primary"} onClick={() => addToShoppingCart(product, 1)}>
                        <ShoppingCartIcon/>   
                    </Button>
                )
            }        
        }else{
            return (
                <Typography style={{position: "absolute", bottom:10, right: 10}} color={"primary"} variant="body2">{product.availabilityString}</Typography>
            )
        }        
    }

    function getShoppingCartItem(product) {
        return state.shoppingCartProducts[state.shoppingCartProducts.map((shoppingCartItem) => shoppingCartItem.productId).indexOf(product.id)];
    }

    function addToShoppingCart(product, count) {
        dispatch({
            type: 'addToShoppingCart',
            shoppingCartProduct: { productId: product.id, quantity: count },
        });
    }

    function existsInShoppingCart(product) {
        return state.shoppingCartProducts.map((shoppingCartItem) => shoppingCartItem.productId).includes(product.id);
    }

    function isAvailable(product) {
        if (product.availability === "IMMEDIATELY") {
            return true;
        }
        return false;
    }
}

const useStyles = makeStyles((el) => ({
    root: {
      padding: 10,
      flexGrow: 1,
    },
    card: {
      borderRadius: 15,
      position: "relative"
    },
    startImage: {
    },
    newsPreviewImg:{
    },
    newsPreviewCard:{
      margin: 15,
      minHeight: 300,
    },
    grid:{
      flexGrow:1,
    },
    homeProductPreviewCard:{
      margin: 10,
      borderRadius: 5,
      border: 5,
    },
}));

export default ProductCard;