import React, { useEffect, useContext, useState } from 'react';
import { AppContext } from '../App';
import Grid from '@material-ui/core/Grid';
import ProductCard from './ProductCard';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import {useStyles} from '../globalStyles';
import SearchBar from "material-ui-search-bar";
import Typography from '@material-ui/core/Typography';
import BottomNavigationBar from './BottomNavigationBar';

export default function Products({heightNavigationBar = 100}) {
    const { state, dispatch } = useContext(AppContext);
    const [ categories, setCategories ] = useState([]);
    const [ searchValue, setSearchValue ] = useState("");

    const classes = useStyles();

    useEffect(() => {
        var productsUrl = window.configs.hostAddress.concat("products?id=").concat(window.configs.producerId);
        
        fetch(productsUrl)
        .then(res => res.json())
        .then(data => {
            if(data.status && data.status >= 400) {
                console.log(data.message)
            } else {
                // setProducts(data);
                dispatch({
                    type: 'setProducts',
                    products: data
                });
                getCategories(data);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
        console.log("shoppingCart.length: " + state.shoppingCartProducts.length)
    }, [dispatch, state.shoppingCartProducts]);

    return (
      <>
        <div className={classes.root} style={{paddingTop: (heightNavigationBar)}}>        
            {/* <Typography style={{zIndex: 999, width: "100%", position: "absolute", animation: "moveDown 0.5s ease-in-out", paddingBottom: 5,}}>
              Warenkorb 34.56 €
            </Typography> */}
            <Toolbar className={`${classes.navbar}`}>
              <SearchBar
                  style={{width:"80%", height:"80%", padding: 0}}
                  placeholder="Suchen ..."
                  value={searchValue}
                  onChange={(newValue) => setSearchValue(newValue)}
                  onCancelSearch={() => {setSearchValue("")}}
                  // onRequestSearch={() => doSomethingWith(this.state.value)}
              />
            </Toolbar>
            {categories.length > 1 &&
            <Toolbar disableGutters={true} variant="dense" className={`${classes.navbar} ${classes.navbarCategories}`}>
                <div style={{paddingLeft: 10, display:"flex"}}>
                <Grid container spacing={0}>
                  <Grid key="categoryAlle" item style={{textAlign: "left", margin: 0, padding: "0 !important", border: "0 none !important"}}>
                  <Button variant="text" size="small" style={{justifyContent: "start"}} className={classes.navbarCategories} onClick={() => {setSearchValue("")}}>
                    <Typography style={{paddingLeft: 3, paddingRight: 3}} onClick={() => {setSearchValue("")}} variant="body1">Alle</Typography>
                  </Button>
                  </Grid>
                {categories.map((category, index) => (
                  // <Button variant="text" onClick={() => {setSearchValue(category)}} fill="clear" size="small" className={classes.navbarCategories} key={index}>
                  <Grid item style={{textAlign: "left", margin: 0, padding: "0 !important", border: "0 none !important"}}>
                    <Button variant="text" size="small" style={{justifyContent: "start"}} className={classes.navbarCategories} onClick={() => {setSearchValue(category)}}>
                    <Typography inline display="inline" onClick={() => {setSearchValue(category)}} style={{paddingLeft: 3, paddingRight: 3}} variant="body1">
                      {category}
                      </Typography>
                    </Button>
                    </Grid>
                  // </Button>
                ))}
                
                </Grid>
                </div>
            </Toolbar>
            }
            <Grid container spacing={1}>
                {state.products.map((product) => (
                product.active && filterProduct(product) /*&& FavoriteUtils.checkIsFavorite(product, state.favorites)*/ ?(
                <Grid key={product.id} item xs={12} sm={6} md={4} lg={3} xl={3} /*onClick={() => selectProduct(product)}*/>
                    <ProductCard product={product} /*setButtonClicked={setButtonClicked} page={"products"}*//>
                </Grid>):''            
                ))}
            </Grid>
        </div>
        <BottomNavigationBar/>
        </>
    );

  //Findet alle genutzten Kategorien
  //Nicht genutzte werden auch nicht angezeigt
  function getCategories(products) {
    var categories = [];
    products.forEach(element => {
        categories.push(element.categoryString);
    });
    categories = categories.filter((item, index) => categories.indexOf(item) === index);
    if(categories.indexOf("Sonstiges") !== -1){
        categories.splice(categories.indexOf("Sonstiges"),1);//Sonstiges Löschen
        categories.push("Sonstiges");//Sonstiges hinzufügen
    }
    setCategories(categories);
  }

  function filterProduct(product) {
    if(searchValue.toLocaleLowerCase().length < 1){
      return true;
    } else if (product.categoryString === searchValue){
      return true;
    } else if(product.title.toLowerCase().indexOf(searchValue.toLocaleLowerCase()) > -1) {
      return true;
    }
    return false;
  }
}
  
