import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default function ProductLabel ({ product, size, hidden = false }) {
    var environmentHost = "https://wms.regioshopper.com";
    const classes = useStyles();
    var containerClass = classes.productDetaillabelContainerSmall;
    var imageClass = classes.productDetaillabelImageSmall;
    // if(size === "large") {
    //     containerClass = "productDetaillabelContainerLarge"
    //     imageClass = "productDetaillabelImageLarge"
    // }
    return (
        <div className={containerClass}>
            {product.bioland ? <img alt="Bioland" ratio={1} hidden={hidden} src={environmentHost + "/images/bioland.png"} className={imageClass} /> : ''}
            {product.demeter ? <img alt="Demeter" ratio={1} hidden={hidden} src={environmentHost + "/images/demeter.png"} className={imageClass} /> : ''}
            {product.naturland ? <img alt="Naturland" ratio={1} hidden={hidden} src={environmentHost + "/images/naturland.png"} className={imageClass} /> : ''}
            {product.fairtrade ? <img alt="fairtrade" ratio={1} hidden={hidden} src={environmentHost + "/images/fairtrade.png"} className={imageClass} /> : ''}
            {product.euBio ? <img alt="Bio" ratio={1} hidden={hidden} src={environmentHost + "/images/eu-bio.png"} className={imageClass} /> : ''}
            {(!product.euBio && !product.bioland && !product.demeter && !product.naturland && !product.fairtrade) ? <img ratio={1} hidden={hidden} alt="" src={environmentHost + "/images/blank.png"} className={imageClass} /> : ''}
        </div>
    )
}

const useStyles = makeStyles((el) => ({
    productDetaillabelContainerSmall: {
        minHeight: 25,
        width:"100%",
        display: "flex",
        // height: 25,
        // position: "absolute",
        // bottom: 0,
    },
  
    productDetaillabelContainerLarge: {
        display: "flex",
        margin: 16,
        position: "absolute",
        bottom: 0,
    },
  
    productDetaillabelImageLarge: {
        float: "left",
        width: "100",
        maxWidth: 50,
        height: "100%",
        maxHeight: 50,
        marginLeft: 10,
        marginRight: 10,
    },
    productDetaillabelImageSmall: {
        float: "left",
        width: "100%",
        maxWidth: 25,
        height: "100%",
        maxHeight: 25,
        marginLeft: 5,
        marginRight: 5,
    }
}));
