import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({

    navbarSticky: {
      zIndex: 999,
      width: "100%",
      position: "fixed",
      top: 0,
      left: 0,
      // boxShadow: "1px 1px 1px #222",
      animation: "moveDown 0.5s ease-in-out",
      color: "white !important",
      backgroundColor: "#2E8099",
      paddingBottom: 5,
    },
    root: {
      // padding: 10,
      paddingRight: 0,
      paddingLeft: 0,
      paddingBottom: 0,
      flexGrow: 1,
      position: "relative",
      backgroundColor: "#fbfbfb"
    },
    navbarText: {
      textDecoration: 'none', 
      color: "#ffffff", 
      fontSize: "large",
      fontFamily: "sans-serif" 
    }, 
    navbar: {
      // background: 'linear-gradient(20deg, #2E8099 50%, #35C6F2 90%)',
      color: "white !important",
      backgroundColor: "#2E8099",
      paddingBottom: 5,
      /* position: fixed !important; */
    },
    navbarCategories:{
      backgroundColor: "#2E8099",
      color: "#ffffff",
      fontSize: "small",
      fontFamily: "Arial, Helvetica, sans-serif",
      textTransform: 'none',
      paddingRight: 7,
      paddingLeft: 0,
      padding: 0,
      margin: 0,
      textAlign: "left"
    },
    card: {
      borderRadius: 15,
      position: "relative"
    },
    startImage: {
    },
    newsPreviewImg:{
    },
    newsPreviewCard:{
      marginTop: 15,
      // minHeight: 300,
    },
    grid:{
      flexGrow:1,
    },
    homeProductPreviewCard:{
      marginTop: 10,
      marginBottom: 10,
      borderRadius: 5,
      border: 5,
      position: "relative",
    },
    shoppingCartOrderOverview:{
      margin: 10,
      borderRadius: 5,
      border: 5,
      position: "relative",
      backgroundColor: "#266B80",
      
    },
    shoppingCartOrderOverviewList:{
      '&::-webkit-scrollbar': {
        width: '0em'
      },
      listStyle: "none",
      // '&::-webkit-scrollbar-button':{

      // },
      // '&::-webkit-scrollbar': {
      //   width: 12,
      // }, 
      // '&::-webkit-scrollbar-track': {
      //     webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      //     borderRadius: 10
      // },
      
      // '&::-webkit-scrollbar-thumb': {
      //     borderRadius: 10,
      //     webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
      // }
     

    },
    titleNewsSection:{
      padding: 20,
    },
    homeImgText:{
      paddingLeft: 10,
      paddingRight: 10,
    },
    homeImgOverlayText:{
      /* height: 30%; */
      textAlign: "justify",
      paddingInlineStart: 20,
      paddingInlineEnd: 20,
      borderRadius: 10,
      zIndex: 99,
      opacity: 0.6,
      backgroundColor: "#efefef",
      color: "#000",
      position: 'absolute',
      top: 30,
      right: 30,
      width: 400,
      paddingRight: 10,
      paddingLeft: 10,
    },
    newsPreviewText:{
      // whiteSpace: "nowrap",
      // textOverflow: "ellipsis",
      // lineClamp: 50,
    },
    progress: {
      alignContent: "center",
      
    }, 
    
    input: {
        color: 'white',
    },
    newsModalDialog: {
      width:800,
    },
    appBar: {
      top: "auto",
      bottom: 0,
      padding: 0,
      margin: 0,
      width: "100%",
    },
  }));