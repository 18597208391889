import React, { useState, useContext, useEffect, useCallback } from 'react';
import { AppContext } from '../App';
import BottomNavigationBar from './BottomNavigationBar';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import SwiperCore, { Autoplay } from 'swiper';
import Skeleton from '@material-ui/lab/Skeleton';
import RsImg from '../utils/RsImg';
import {
  Link,
} from "react-router-dom";
import {useStyles} from '../globalStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar  from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

SwiperCore.use([Autoplay]);

export default function Home({heightNavigationBar = 100}) {
  const { state, dispatch } = useContext(AppContext);
  const classes = useStyles();
  const [producer, setProducer] = useState();
  const [homeImgLoaded, setHomeImgLoaded] = useState(false);
  const [newsLoaded, setNewsLoaded] = useState(false);
  const [productPreviewLoaded, setProductPreviewLoaded] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedNews, setSelectedNews] = useState();
  const [newsfeedPreviewMinHeight, setNewsfeedPreviewMinHeight] = useState(300);

  function handleImageLoad() {
    setHomeImgLoaded(true);
  }

  useEffect(() => {
    console.log("Window Width: " + window.screen.width);
    var targetUrl = window.configs.hostAddress.concat("home?id=").concat(window.configs.producerId);
    window.configs.hostAddress.concat("home?id=").concat(window.configs.producerId)
    fetch(targetUrl)
      .then(res => res.json())
      .then(data => {
        setProducer(data);
        console.log("newslist: " + data.newslist);
    },[]);

    var productsUrl = window.configs.hostAddress.concat("products?id=").concat(window.configs.producerId);
    fetch(productsUrl)
      .then(res => res.json())
      .then(data => {
        if(data.status && data.status >= 400) {
          console.log(data.message)
        } else {
          dispatch({
            type: 'setProducts',
            products: data
          });
          
        }
      })
      .catch((error) => {
        console.error('Error:', error);
    });
  }, [dispatch]);


  return (  
    <>
    <div className={classes.root} style={{paddingTop: heightNavigationBar + 20, paddingRight: 10, paddingLeft: 10, paddingBottom: 20}}>
      <Backdrop style={{zIndex: 99, color:'#fff'}} open={!homeImgLoaded}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
        {producer?.introduction.imageList.map((newsImage, imageIndex) => (
          <Card key={"key".concat(newsImage.id)} className={classes.card}>
            <RsImg 
              ratio={10/4}
              onRsLoad={() => handleImageLoad()} 
              imgSrc={"https://wms.regioshopper.com".concat(newsImage.absPath)}>
            </RsImg>

            <Typography className={window.screen.width > 1200 ? classes.homeImgOverlayText : classes.homeImgText} variant="body2" color="textSecondary" style={{display: homeImgLoaded? 'block': 'none'}}>
                {producer.introduction.text.split('\n').map((line) => (
                  <p key={line}>{line}</p>
                ))}
            </Typography>
          </Card>
        ))}
        </Grid>
        {!(window.innerWidth < 1279) && renderProductPreview(state.products, classes, newsLoaded)}
      </Grid>
      {renderNews()}
      {renderNewsModal()}
      
    </div>
    <BottomNavigationBar hidden={!homeImgLoaded}/>
    </>
  );

  function renderNews (){
    return(
      <div>
        {/* <h2>Neuigkeiten</h2> */}
        <Grid container spacing={1}>
          {producer?.newsList
                      .filter(news => !news.introduction)
                      .sort((a,b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                      .map((newsEntry) => (
          <Grid key={newsEntry.id} item xs={12} sm={6} md={4} lg={3} xl={3} style={{display: homeImgLoaded? 'block': 'none'}}>
            <div onClick={() => {setModalVisible(true); setSelectedNews(newsEntry)}}>
              <Card ref={(elem) => {(elem && elem.offsetHeight > newsfeedPreviewMinHeight) && setNewsfeedPreviewMinHeight(elem.offsetHeight)}} style={{minHeight: newsfeedPreviewMinHeight, position: "relative"}} className={classes.newsPreviewCard}>
                  <RsImg 
                    onRsLoad={() => setNewsLoaded(true)} 
                    startLoading={homeImgLoaded}
                    ratio={10/4}
                    // imgHeight={gridHomeImg ? (gridHomeImg.current.offsetWidth/10)*4 : 10} 
                    imgSrc={"https://wms.regioshopper.com".concat(newsEntry.imageList[0].absPath)}>
                  </RsImg>
                  <CardContent style={{marginBottom: 25}}>
                    <Typography variant="h6">{newsEntry.title}</Typography>
                    <Typography className={classes.newsPreviewText} variant="body2" color="textSecondary" component="p">
                      {newsEntry.text.split(' ').slice(0,40).map((word)=>(
                        <>{word.concat(" ")}</>
                      ))}
                      {newsEntry.text.split(' ').length > 40 && <> ...</> }
                    </Typography>
                  </CardContent>
                  <Button style={{position: "absolute", bottom: 10, right: 5}} variant="contained" size="small" color="primary">Mehr erfahren</Button>
              </Card>
            </div>
          </Grid>
          ))}   
        </Grid>
      </div>
    )
  }

  function renderProductPreview(products){
    if(window.innerWidth > 1279){
      return (
        <Grid item xs={false} sm={false} md={false} lg={3} xl={3} >
          {(products?.length > 1 ) && [getRandomInt(products?.length),getRandomInt(products?.length)].map((initial)=>(
            <Swiper
              loop={true}
              autoplay={{
                delay: 5000 + initial*50,
                disableOnInteraction: false
              }}
              speed={2000}
              initialSlide= {initial}
              spaceBetween={500}
              slidesPerView={1}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log('onSwipe')}
              >

              {products?.map((product) => (
                <SwiperSlide>
                  <Card className={classes.homeProductPreviewCard} >
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <RsImg 
                            onRsLoad={() => setProductPreviewLoaded(true)} 
                            startLoading={newsLoaded}
                            ratio={1}
                            // imgHeight={gridHomeImg ? (gridHomeImg.current.offsetWidth/10)*4 : 10} 
                            imgSrc={"https://wms.regioshopper.com/images/product_" + product.id + ".png"}>
                          </RsImg>
                        </Grid>
                        <Grid item xs={6}>
                          {productPreviewLoaded ?
                          <CardContent>
                            <Typography gutterBottom variant="subtitle1" component="h5">
                              {product.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              {product.price.toFixed(2)}{" €"}{product.metric ? " / " : ""} {product.metric}
                            </Typography> 
                            <Link style={{textDecoration: 'none', position:"absolute", bottom:5, right:5}} to={"/products"}>
                              <Button variant="contained" size="small" color="primary" >Zum Shop</Button>
                            </Link>
                          </CardContent>
                          :
                          <CardContent>
                            <Skeleton variant="text"/>
                            <Skeleton variant="text"/>
                            <Skeleton variant="text"/>
                            <Skeleton variant="text"/>
                          </CardContent>
                          }
                        </Grid>
                      </Grid>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
            ))}
          </Grid>
      )
    }
  }

  function renderNewsModal(){
    return(
      <Dialog fullWidth={true} fullScreen={window.innerWidth < 800} scroll="body" maxWidth="md" onClose={() => setModalVisible(false)} aria-labelledby="customized-dialog-title" open={modalVisible}>
          <Toolbar className={`${classes.navbar}`}>
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <Typography variant="h6">
                    {selectedNews && selectedNews.title}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton style={{margin: 0, padding:0}} edge="end" color="inherit" onClick={() => setModalVisible(false)} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>       
          {selectedNews &&
          <div>
            <RsImg ratio={10/4} imgSrc={"https://wms.regioshopper.com".concat(selectedNews.imageList[0].absPath)}>
            </RsImg>
          </div>
          }
          <DialogContent dividers>
            {selectedNews && selectedNews.text.split('\n')
            .map((line) => (<Typography>{line}</Typography>))}
          </DialogContent>
      </Dialog>
    )
  }
}


  

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
