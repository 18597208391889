import React, {useRef, useState} from 'react';
import Fade from '@material-ui/core/Fade';
import Skeleton from '@material-ui/lab/Skeleton';


/*
Eigene BildKomponente:
Anforderungen: 
- Bild anzeigen wie Img Komponente
- Skeleton anzeigen, falls Bild noch nicht geladen
- Wenn geladen, mit Animation einblenden (FadeIn)
- onLoad-Event zurückgeben (onRsLoad)
- Ladestart bestimmen können (startLoading)
- Bildgrößenverhältnis übergeben können (ratio)
*/

function RsImg({imgSrc, ratio, onRsLoad = () => {}, startLoading = true, alt = "", originHeight = 0}){
    const [rsLoaded, setRsLoaded] = useState(false);
    const divHomeImg = useRef(null);

    const handleRsOnLoad = (e) => {
        onRsLoad();
        setRsLoaded(true);
    };

    const getImgSrc = (path) => {
        if(window.innerWidth < 500){
            return path.replace(".png", "_small.webp")
        }else if(window.innerWidth < 800){
            return path.replace(".png", "_medium.webp")
        }else{
            return path.replace(".png", ".webp")
        }
    };

    return (
        <div width={"100%"} ref={divHomeImg}>
            {startLoading &&
            <Fade in={rsLoaded} timeout={2000}>
                {/* <source srcset={imgSrc.replace("png", "webp")}  type="image/webp" /> */}
                {/* <source srcset="/images/cereal-box.jp2" type="image/jp2" /> */}
                <img
                    style={{display: rsLoaded? 'block': 'none'}}
                    onLoad={(e) => {
                        handleRsOnLoad();
                    }}
                    onError={(e)=>{e.target.onerror = null; e.target.src = imgSrc}}
                    alt={alt}
                    width="100%"
                    src={getImgSrc(imgSrc)}
                />
            </Fade>
            
            }
            <Skeleton 
                variant="rect" 
                height={divHomeImg.current ? (divHomeImg.current.offsetWidth/ratio) : originHeight}
                style={{display: rsLoaded ? 'none':'block'}}
            />
        </div>
    )
}

export default RsImg;