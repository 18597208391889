import React, { useContext, useEffect, useState} from 'react';
import { AppContext } from '../App';
import BottomNavigationBar from './BottomNavigationBar';
import Grid from '@material-ui/core/Grid';
import ProductCard from './ProductCard';
import {useStyles} from '../globalStyles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CreateIcon from '@material-ui/icons/CreateOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

export default function ShoppingCart({heightNavigationBar = 100}) {
    const { state, dispatch } = useContext(AppContext);
    const classes = useStyles();
    const [fetchtimes, setFetchtimes] = useState([]);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [reservationModalVisible, setReservationModalVisible] = useState(false);
    const [selectOnlyFetchtimeModalVisible, setSelectOnlyFetchtimeModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reservationError, setReservationError] = useState("");
    const [showReservationError, setShowReservationError] = useState(false);
    const [reservationErrorUsername, setReservationErrorUsername] = useState(false);
    const [reservationErrorMail, setReservationErrorMail] = useState(false);
    const [openWeekdays, setOpenWeekdays] = useState({"Montag": false, "Dienstag": false, "Mittwoch": false, "Donnerstag": false, "Freitag": false, "Samstag": false, "Sonntag": false});

    useEffect(() => {
        var targetUrl = window.configs.hostAddress.concat("fetchtimes?id=").concat(window.configs.producerId);
        fetch(targetUrl)
          .then(res => res.json())
          .then(data => {
              setFetchtimes(data);
          });
    }, []);

    return (
        <>
        <div className={classes.root} style={{postion: "relative", paddingTop: (heightNavigationBar)}}>        
            <Grid container spacing={0}>
                <Hidden smDown>
                    <Grid item xl={8} lg={8} md={6}>
                        <Grid container spacing={1}>
                            {state.shoppingCartProducts && state.products.filter((product) => existsInShoppingCart(product)).map((shoppingCartProduct) => (
                                <Grid key={shoppingCartProduct.id} item xs={12} sm={12} md={6} lg={6} xl={4} /*onClick={() => selectProduct(product)}*/>
                                    <ProductCard  product={shoppingCartProduct} />
                                </Grid>            
                            ))}
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12} >
                    <div style={{postion: "absolute", top: 10, }}>
                    <Card className={classes.shoppingCartOrderOverview}>
                        <CardContent >
                            <Typography variant="h5" color="secondary" gutterBottom>
                                Bestellübersicht
                            </Typography>
                            {state.shoppingCartProducts.length === 0 && 
                                <Typography variant="body1" color="secondary">Dein Warenkorb ist leer</Typography>
                            }
                            <List className={classes.shoppingCartOrderOverviewList} style={{minHeight: window.innerHeight * 0.30, maxHeight: window.innerHeight * 0.60, overflow: 'auto'}}>
                                {state.shoppingCartProducts && state.products.filter((product) => existsInShoppingCart(product)).map((shoppingCartProduct) => (
                                <div key={shoppingCartProduct.id} style={{borderBottom: "1px solid #ffffff", margin:"8px"}}>
                                    <Grid container justify="space-between">  
                                        <Typography variant="body2" align="left" color="secondary" gutterBottom>
                                        {getShoppingCartItem(shoppingCartProduct).quantity}x {shoppingCartProduct.title}
                                        </Typography>         
                                        <Typography variant="body2" align="right" color="secondary">
                                            {(getShoppingCartItem(shoppingCartProduct).quantity * shoppingCartProduct.price).toFixed(2)} €
                                        </Typography>
                                    </Grid>
                                    <ButtonGroup style={{paddingBottom: "10px"}} size="small" color="secondary" aria-label="outlined primary button group">
                                        <Button style={{fontSize: "10px"}}  size="small" fontSize="small" className="removeFromShoppingCartButton" color="secondary"  fill="clear" onClick={() => addToShoppingCart(shoppingCartProduct, -1 * getShoppingCartItem(shoppingCartProduct).quantity )}>
                                            Entfernen
                                        </Button>
                                        <Button style={{fontSize: "16px"}} size="small" className="removeFromShoppingCartButton" color="secondary" fill="clear" onClick={() => addToShoppingCart(shoppingCartProduct, -1)} >
                                            <RemoveIcon size="small" fontSize="inherit" slot="icon-only"/>
                                        </Button>
                                        <Button size="small" style={{fontSize: "10px"}}  fill="clear">
                                            {getShoppingCartItem(shoppingCartProduct).quantity}
                                        </Button>
                                        <Button style={{fontSize: "16px"}} className="addToShoppingCartButton" color="secondary" fill="solid" onClick={() => addToShoppingCart(shoppingCartProduct, 1)}>
                                            <AddIcon size="small" fontSize="inherit" slot="icon-only"/>
                                        </Button>
                                    </ButtonGroup>
                                </div>        
                                ))}
                            </List>
                            
                            <Grid container justify="space-between" style={{marginTop: 20}}>  
                                <Typography variant="body1" align="left" color="secondary" gutterBottom>
                                    Gesamtsumme
                                </Typography>         
                                <Typography variant="body1" align="right" color="secondary">{getTotalCount()} €</Typography>
                            </Grid>
                            <Typography variant="body2" align="left" color="secondary" gutterBottom>        
                                (inkl. Mehrwertsteuer)
                            </Typography>    
                            
                        </CardContent>
                        <CardActions>
                            <Button style={{width:"100%"}} size="large" variant="contained" onClick={() => setReservationModalVisible(true)}>Zur Kasse</Button>
                        </CardActions>
                    </Card>
                    {renderFetchtimeSelector()}
                    </div>
                </Grid>
            </Grid>
            {renderReservationModal()}
            {renderOnlySelectFetchtimeModal()}
        </div>
        <BottomNavigationBar/>
        </>
    );

    function renderFetchtimeSelector(){
        return (
            <Card className={classes.shoppingCartOrderOverview}>
                <CardContent >
                    <Button style={{padding:"0", textTransform:'none', width:"100%"}} onClick={() => setSelectOnlyFetchtimeModalVisible(true)}>
                        <Grid container justify="space-between" style={{marginTop: 20}}>  
                            <EventAvailableIcon style={{padding:"0"}} fontSize="large" color="secondary"></EventAvailableIcon>         
                            <Typography style={{display: 'inline-block', alignItems: 'center'}} variant="body1" color="secondary" gutterBottom>
                                {fetchtimes.length > 0 && state.selectedFetchtime ? 
                                     fetchtimeToString(state.selectedFetchtime) : 
                                    "Kein Abholtermin ausgewählt"
                                }
                            </Typography> 
                            <CreateIcon style={{padding:"0"}} fontSize="large" color="secondary"></CreateIcon>
                        </Grid>
                    </Button>    
                    <Button style={{padding:"0", textTransform:'none', width:"100%"}}>
                        <Grid container justify="space-between" style={{marginTop: 20}}>  
                            <HomeWorkOutlinedIcon style={{padding:"0"}} fontSize="large" color="secondary"/>         
                            <Typography style={{display: 'flex', alignItems: 'center'}} variant="body1" color="secondary" gutterBottom>
                                {/* Bisher keine Filiale ausgewählt */}
                                {window.configs.producerName}
                            </Typography> 
                            <CreateIcon style={{padding:"0"}} fontSize="large" color="secondary"/>
                        </Grid>    
                    </Button>          
                </CardContent>
                {/* <CardActions>
                    <Button variant="contained">Zur Kasse</Button>
                </CardActions> */}
            </Card>      
        )
    }

    function renderReservationModal(){
        return(           
          <Dialog fullWidth={true} fullScreen={window.innerWidth < 800} scroll="body" maxWidth="sm" onClose={() => setReservationModalVisible(false)} aria-labelledby="customized-dialog-title" open={reservationModalVisible}>
                <Backdrop style={{zIndex: 99, color:'#fff'}} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
              <Toolbar className={`${classes.navbar}`}>
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <Typography variant="h6">
                        Reservierung
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton style={{margin: 0, padding:0}} edge="end" color="inherit" onClick={() => setReservationModalVisible(false)} aria-label="close">
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Toolbar>
              
              <DialogContent dividers style={{position: "relative"}}>
                { showReservationError &&
                <Typography color="error" variant="h6">
                    {reservationError}
                </Typography>
                }
                <TextField
                    required
                    error={reservationErrorUsername}
                    label="Name"
                    color="secondary"
                    // style={{ margin: 8 }}
                    placeholder="Name"
                    helperText={reservationErrorUsername ? "Bitte überprüfen Sie den eingegebenen Namen." : ""}
                    fullWidth
                    value={username}
                    onChange={(val) => setUsername(val.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{paddingBottom: 10}}
                    />
                <TextField
                    required
                    error={reservationErrorMail}
                    label="Mail"
                    color="secondary"
                    // style={{ margin: 8 }}
                    placeholder="E-Mail"
                    helperText={reservationErrorMail ? "Bitte überprüfen Sie die eingegebene Mail-Adresse":""}
                    fullWidth
                    value={email}
                    onChange={(val) => setEmail(val.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{paddingBottom: 10}}
                    />
                {renderSelectFetchtime()}
                <ButtonGroup style={{width:"100%", marginTop:20}}>
                    <Button onClick={() => setReservationModalVisible(false)} color="secondary" size="large" style={{width:"100%"}} variant="contained">
                        Abbrechen
                    </Button>
                    <Button onClick={() => validateReservation()} style={{width:"100%"}} color="primary" size="large" variant="contained">
                        Reservieren
                    </Button>
                </ButtonGroup>
              </DialogContent>
          </Dialog>              
        )
    }

    function renderWeekdayFetchtimes(weekdayString){
        return fetchtimes.filter((fetchtime) => fetchtime.weekdayString === weekdayString).map((fetchtime) => (
            <Collapse in={openWeekdays[weekdayString]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button key={fetchtime.id} style={{width:"90%", marginLeft: "10%", marginTop: 10, marginBottom: 10, textTransform: 'none'}} 
                        onClick={() => setSelectedFetchtime(fetchtime.id)} > 
                        <ListItemIcon>
                            <QueryBuilderIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText fontSize="small" color="textSecondary" >{fetchtime.weekdayString} ({fetchtime.dateString} {fetchtime.starttimeString} - {fetchtime.endtimeString})</ListItemText>
                            <Checkbox
                                style={{padding:"0"}}
                                checked={(fetchtime.id === state.selectedFetchtime)}
                                color="default"
                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                            />
                    </ListItem>
                </List>
            </Collapse>
        ))
    }

    function renderSelectFetchtime(){
        return (
            <div>
                <Typography variant="h6">Abholzeit</Typography>
                {fetchtimes.length > 7 ?
                Array.from(new Set(fetchtimes.map((fetchtime) => fetchtime.weekdayString))).map((weekdayString) => 
                    <div style={{borderBottom:"1px solid #000000"}}>
                        <ListItem button onClick={() => setOpenWeekdays({...openWeekdays, [weekdayString]: !openWeekdays[weekdayString]})}>
                            <ListItemIcon>
                                <QueryBuilderIcon />
                            </ListItemIcon>
                            <ListItemText>{weekdayString}</ListItemText>
                            {openWeekdays[weekdayString] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        {renderWeekdayFetchtimes(weekdayString)}
                    </div>
                )
            :
            fetchtimes.map((fetchtime) => (
                    <Button key={fetchtime.id} style={{width:"100%", marginTop: 10, marginBottom: 10, borderBottom:"1px solid #000000", textTransform: 'none'}} 
                        onClick={() => setSelectedFetchtime(fetchtime.id)} > 
                        <Grid container justify="space-between">
                            <Typography variant="body1" color="textSecondary" component="p">{fetchtime.weekdayString} ({fetchtime.dateString} {fetchtime.starttimeString} - {fetchtime.endtimeString})</Typography>
                            <Checkbox
                                style={{padding:"0"}}
                                checked={(fetchtime.id === state.selectedFetchtime)}
                                color="default"
                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                            />
                        </Grid>
                    </Button>
                ))}
            </div>
        )
    }

    function renderOnlySelectFetchtimeModal(){
        return(
            <Dialog fullWidth={true} fullScreen={window.innerWidth < 800} scroll="body" maxWidth="sm" onClose={() => setSelectOnlyFetchtimeModalVisible(false)} aria-labelledby="customized-dialog-title" open={selectOnlyFetchtimeModalVisible}>
                <Toolbar className={`${classes.navbar}`}>
                <Grid container spacing={1}>
                    <Grid item xs={11}>
                    <Typography variant="h6">
                        Abholtermin
                    </Typography>
                    </Grid>
                    <Grid item xs={1}>
                    <IconButton style={{margin: 0, padding:0}} edge="end" color="inherit" onClick={() => setSelectOnlyFetchtimeModalVisible(false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    </Grid>
                </Grid>
                </Toolbar>
                <DialogContent dividers>
                {renderSelectFetchtime()}
                <ButtonGroup style={{width:"100%", marginTop:20}}>
                    <Button onClick={() => setSelectOnlyFetchtimeModalVisible(false)} style={{width:"100%"}} variant="contained" color="primary" size="large">
                        Speichern
                    </Button>
                </ButtonGroup>
                </DialogContent>
            </Dialog>
            
        )
    }

    async function sendReservation() {
        setLoading(true);
        const response = await fetch(window.configs.hostAddress + "reservation?id=" + window.configs.producerId, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
                email: email,
                fetchtimeId: state.selectedFetchtime,
                shoppingCart: state.shoppingCartProducts
            })
        });
        if(response.ok){
            dispatch({type: 'emptyShoppingCart'});
            setReservationModalVisible(false);
            setSelectedFetchtime(null);
            // dispatch({type: 'setShowReservationModal',showReservationModal: false});
        }else{
            // setAlertMsg('Ein Fehler ist aufgetreten. Bitte Überprüfen Sie ihre eingegebene Mail-Adresse oder Versuchen Sie es später erneut.');
            // setShowMailWariningAlert(true);
        }
        setLoading(false);
    };

    function validateEmail(email) {
        // const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(email);
    }

    function validateReservation() {
        if(username.length < 1){
            setReservationErrorUsername(true);
            // setReservationError('Bitte einen Namen eingeben.');
            // setShowReservationError(true);
        }
        else if(!validateEmail(email)){
            setReservationErrorMail(true);
            // setReservationError('Bitte überprüfen Sie ihre Mailadresse.');
            // setShowReservationError(true);
        }
        else if(!fetchtimes.map((fetchtime) => fetchtime.id).includes(state.selectedFetchtime)){
            setReservationError('Bitte eine Abholzeit auswählen.');
            setShowReservationError(true);
        }
        else if(state.shoppingCartProducts.length === 0){
            setReservationError('Dein Warenkorb ist leer.');
            setShowReservationError(true);
        }
        else{
            sendReservation();
        }
    };

    function existsInShoppingCart(product){
        return Array.from(state.shoppingCartProducts.map((item) => item.productId)).includes(product.id);
    }

    function getShoppingCartItem(product) {
        // return ProductCard.getShoppingCartItem(product);
        return state.shoppingCartProducts[state.shoppingCartProducts.map((shoppingCartItem) => shoppingCartItem.productId).indexOf(product.id)];
    }

    function addToShoppingCart(product, count) {
        // ProductCard.addToShoppingCart(product, count);
        dispatch({
            type: 'addToShoppingCart',
            shoppingCartProduct: { productId: product.id, quantity: count },
        });
    }

    function setSelectedFetchtime(fetchtimeId) {
        dispatch({
            type: 'setSelectedFetchtime', 
            selectedFetchtime: fetchtimeId
        });
    }

    function getTotalCount(){
        var total = 0.0;
    
        for (let item of state.shoppingCartProducts) {
          var product = getProductObjectById(item.productId);
          if (product != null) {
            total = total + (item.quantity * getProductObjectById(item.productId).price);
          }
        }
        return total.toFixed(2);
    }

    function getProductObjectById(id){
        var index = state.products.map((product) => product.id).indexOf(id);
        if (index < 0) {
          return null;
        }
        return state.products[index];
    }

    function getFetchtimeById(id){
        var index = fetchtimes.map((fetchtime) => fetchtime.id).indexOf(id);
        if (index < 0) {
          return null;
        }
        return fetchtimes[index];
    }

    function fetchtimeToString(id){
        var fetchtime = getFetchtimeById(id);
        return fetchtime.weekdayString + " (" + fetchtime.dateString + " " + fetchtime.starttimeString + " - " + fetchtime.endtimeString + ")";
    }
  }