import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../App';
import Toolbar  from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import {
    Link,
  } from "react-router-dom";
import Button from '@material-ui/core/Button';
import {useStyles} from '../globalStyles';

export default function BottomNavigationBar({hidden = false}) {
    const { dispatch } = useContext(AppContext);
    const classes = useStyles();

    return(
      <AppBar position="relative" color="primary" className={classes.appBar} style={{display: hidden ? 'none' : 'block'}}>
        <Toolbar>
          <Button>
            <Link to={"/impressum"} style={{ textDecoration: 'none', textTransform: 'none', fontSize:"small" }} className={classes.navbarText}>
              Datenschutz
            </Link>
          </Button >
          <Button>
            <Link to="/impressum" style={{ textDecoration: 'none', textTransform: 'none', fontSize:"small"  }} className={classes.navbarText}>
              Impressum
            </Link>
          </Button >
          <Button>
            <Link to={{"pathname": "https://regioshopper.com"}} target="_blank" style={{ textDecoration: 'none', textTransform: 'none', fontSize:"small"  }} className={classes.navbarText}>
              RegioShopper
            </Link>
          </Button >
        </Toolbar>
      </AppBar>
    )
}