const reducer = (state, action) => {
    switch(action.type){
      case "setSelectedFetchtime":
        return{
          ...state,
          selectedFetchtime: action.selectedFetchtime
        };
      case "emptyShoppingCart":
        return{
          ...state,
          shoppingCartProducts: []
        };
      case "setShowReservationModal":
        return { 
          ...state, 
          showReservationModal: action.showReservationModal
        };
      case "selectProduct":
        return {
          ...state,
          showProductDetailModal: true,
          selectedProduct: action.product
        }
      case "setShowProductDetailModal":
        return { 
          ...state, 
          showProductDetailModal: action.showProductDetailModal
        };
      case "selectNews":
        return {
          ...state,
          showNewsModal: true,
          selectedNews: action.news
        }
      case "setShowNewsModal":
        return { 
          ...state, 
          showNewsModal: action.showNewsModal
        };
      case "closeModal":
        return { 
          ...state,
          showReservationModal: false,
          showProductDetailModal: false,
          showNewsModal: false
        }
      case "setProducts":
        if(state.products === action.products){
          return state;
        }else{
          return { 
            ...state,
            products: action.products
          };
        }
      case "addToShoppingCart":
        var index = state.shoppingCartProducts.map((shoppingCartItem) => shoppingCartItem.productId).indexOf(action.shoppingCartProduct.productId);
        if(index > -1){
          state.shoppingCartProducts[index].quantity = state.shoppingCartProducts[index].quantity + action.shoppingCartProduct.quantity;   
          if(state.shoppingCartProducts[index].quantity === 0){
            state.shoppingCartProducts.splice(index, 1);
          }  
          return { 
            ...state, 
            shoppingCartProducts: state.shoppingCartProducts
          };
        }
        else{
          return { 
            ...state, 
            shoppingCartProducts: [...state.shoppingCartProducts, action.shoppingCartProduct]
          };
        }
      case "addToFavorites":
        return {
          ...state,
          favorites: state.favorites.concat(action.productId)
        }
      case "removeFromFavorites":
        var favoriteIndex = state.favorites.indexOf(action.productId);
        if(favoriteIndex > -1) {
          state.favorites.splice(favoriteIndex, 1);
        }
        return {
          ...state,
          favorites: state.favorites
        }
      default :
        return state;
    }  
  }

  export default reducer;